import { AspectRatio, Grid, Box, useThemeUI, jsx } from 'theme-ui';
import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Heading from '../components/Heading';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Link from '../components/Link';
import Layout from '../global/Layout';

const IndexPage = ({ data }) => {
  const {
    theme: { colors },
  } = useThemeUI();
  return (
    <Layout background="pig">
      <Helmet>
        <meta name="theme-color" content={colors.pig} />
      </Helmet>
      <Header appearance="dark" />
      <Grid
        columns={['repeat(4, 1fr)', 'repeat(12, 1fr)']}
        gap={4}
        sx={{
          maxWidth: 240,
          width: '100%',
          minHeight: '100%',
          flexBasis: '100%',
          flexGrow: 1,
          mx: 'auto',
          color: 'white',
        }}
      >
        <Box
          as="nav"
          sx={{
            gridColumn: ['1/ span 4', '4/ span 6'],
            mt: [10, 20],
            mb: [10, 20],
          }}
        >
          <Heading variant="subtitle" color="white">
            Works
          </Heading>

          {data.allSanityProject.edges.map(({ node }, index) => (
            <Heading
              variant="h0"
              color="white"
              as="div"
              key={node.slug.current}
            >
              <Link to={node.slug.current}>{node.title}</Link>
            </Heading>
          ))}
        </Box>
      </Grid>
      {/* <Footer /> */}
    </Layout>
  );
};

export const query = graphql`
  query ProjectList {
    allSanityProject(sort: { order: ASC, fields: endedAt }) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

export default IndexPage;
